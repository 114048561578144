<template>
	<article class="wallpaper" id="js_wallpaper_container">
		<h1 class="wallpaper__header"><img src="@/assets/img/moon-phase/title.png" alt="毎月壁紙プレゼント 月の満ち欠けカレンダー" /></h1>

		<!-- イントロ -->
		<p class="wallpaper__intro">
			月の満ち欠け、特に「満月」の日は、気持ちが高まりやすく、人によっては、頭痛、倦怠感、注意力などの低下が起こりやすいといわれています。<br><br>頭痛ーるの「月の満ち欠けカレンダー」壁紙で、体調管理をしてみてはいかがでしょうか。
		</p>
		<!-- /イントロ -->

		<!-- カレンダー -->
		<section class="wallpaper__contents">
			<h2 class="wallpaper__contents--title"><img src="@/assets/img/moon-phase/top.png" alt="今月のカレンダーだよ！ 下の画像を長押ししてダウンロードしてニャ！" /></h2>
			<p class="wallpaper__contents--img"><img src="@/assets/img/moon-phase/moonphase_calendar.jpg" alt="月の満ち欠けカレンダー" /></p>
			<p class="wallpaper__contents--text"><em>スマートフォン用壁紙の設定方法</em><br>ご利用のスマートフォンの画面サイズに合わせて表示範囲を指定し、設定ください。<br><span>※スマートフォンの機種により、サイズが合わない場合がありますのでご了承ください。</span></p>
		</section>
		<!-- /カレンダー -->

		<!-- マイページ登録 -->
		<aside class="wallpaper__mypage" v-if="!isMyPage">
			<p class="wallpaper__mypage--text"><em>マイページ登録</em>をすると簡単にアクセスできるよ！</p>

			<div class="sugotoku-lp__mypage">
				<div class="balloon__wrapper">
					<div class="balloon">
						<div class="balloon__inner">
							<div class="balloon__text--wrapper">
								<p class="balloon__text--title">とっても便利！</p>
								<p class="balloon__text">マイページ登録をすると、気圧予報や新着情報が毎日簡単にチェックできるよ&#9834;</p>
							</div>
							<div class="balloon__img--wrapper">
								<i class="sprite__mypage-button mypage-img" />
							</div>
						</div>
						<div class="balloon__close" @click="clickCancelMypage($event)">
							<i class="sprite__mypage-button close-icon" />
						</div>
					</div>
				</div>
				<div :class="[
					'dcm_favorite_separateButton',
					'dcm_typeRed',
					'mypage__button',
					isLocalClass,
				]">
					<p>
						<a @click.stop="clickMypageButton($event)"><span>マイページに追加</span></a>
					</p>
				</div>
			</div>
		</aside>
		<!-- /マイページ登録 -->

		<!-- アウトロ -->
		<div class="wallpaper__appdownload growthhack-f">
			<div class="about-balloon is-type02">
				<div class="about-balloon__img is-type02"><i class="about-balloon__bg sprite sprite-about_balloon_img02" /></div>
				<p class="about-balloon__box is-type02">体調管理には「頭痛ーる」もオススメじゃ！</p>
			</div>
			<h2 class="growthhack-f__title">
				<i class="growthhack-f__title--img sprite_growthhack-f img01" />
				<p class="growthhack-f__title--text">あなたの<span class="is-large">その不調、<br>低気圧のせい</span>かも!?</p>
			</h2>
			<div class="growthhack-f__symptoms">
				<img src="@/assets/img/growthhack/about_intro_img01.png" alt="頭痛 落ち込み 肩こり だるさ むくみ" class="growthhack-f__symptoms--img">
			</div>
			<div class="growthhack-f__allright">
				<p class="growthhack-f__allright--text">頭痛ーるは不調の原因となる「気圧変化」をお知らせします！</p>
			</div>
			<div class="growthhack-f__caution">
				<p class="growthhack-f__caution--right">低気圧で体調崩すかも。早めに用事を済ませよう！</p>
				<p class="growthhack-f__caution--left">痛みが出る前に薬を準備しておこう！</p>
			</div>
			<div class="growthhack-f__with-zutool">
				<h2 class="growthhack-f__with-zutool--title">
					with <span class="is-large">頭痛ーる</span>で<br>気圧と上手に付き合って<br>快適な毎日へ♪
				</h2>
				<i class="growthhack-f__with-zutool--img sprite_growthhack-f img02" />
			</div>
			<div class="about-rightnow__login">
				<a v-if="isAndroid" href="dcmdam://launch?url=http%3A%2F%2Fapp-manager.docomo.ne.jp%2FSpApps%2FdetailApp%3Fdcmstore_view%3Dnone%26cId%3D10000023012" class="about-rightnow__login--button" id="trackingWallpaperAppDownload01_android">便利なアプリをダウンロード<span>スゴ得ユーザーは使い放題！</span></a>
				<a v-else @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=appDownload`, _backPage: 'top' } })" id="trackingWallpaperAppDownload01_ios" class="about-rightnow__login--button">便利なアプリをダウンロード<span>スゴ得ユーザーは使い放題！</span></a>
			</div>
			<div class="about-balloon is-type01">
				<p class="about-balloon__box is-type01">最近なんだか体調が悪い…という人は、セルフチェックしてみるニャ！</p>
				<div class="about-balloon__img is-type01"><i class="about-balloon__bg sprite sprite-about_balloon_img01" /></div>
			</div>
			<dl class="wallpaper__appdownload--diagnose">
				<dt class="is-balance-check">\雨の日に体調が悪くなりやすい人はチェック/</dt>
				<dd>
					<a @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=selfcheckMeteoropathy` } })">
						<img src="@/assets/img/sugotoku_diagnose_bnr02.png" alt="＼あなたは大丈夫？／医師監修 気象病セルフチェック" />
					</a>
				</dd>
				<dt class="is-meteoropathy-check">\慢性的な体調不良の方はこちらをチェック/</dt>
				<dd>
					<a @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=balancecheckAutonomicNerves` } })">
						<img src="@/assets/img/sugotoku_diagnose_bnr01.png" alt="医師監修 自律神経のバランスチェック！" />
					</a>
				</dd>
			</dl>
			<section class="about-character">
				<div class="about-character__inner">
					<h2 class="about-character__heading">頭痛ーるキャラクター紹介</h2>
					<ul class="about-character__list">
						<li class="about-character__item">
							<div class="about-character__visual is-type01">
								<i class="about-character__visual--img sprite sprite-about_character_img01" />
							</div>
							<div class="about-character__intro">
								<h3 class="about-character__intro--heading">ふくろう博士（ヒロシ）</h3>
								<p class="about-character__intro--text">気象予報士。男手ひとつで子猫のマロを育てている。</p>
							</div>
						</li>
						<li class="about-character__item">
							<div class="about-character__intro">
								<h3 class="about-character__intro--heading">マロ</h3>
								<p class="about-character__intro--text">ふくろう博士の養子。ある寒い雨の日にひとりで泣いているところを博士に保護された。</p>
							</div>
							<div class="about-character__visual is-type02">
								<i class="about-character__visual--img sprite sprite-about_character_img02" />
							</div>
						</li>
						<li class="about-character__item">
							<div class="about-character__visual is-type03">
								<i class="about-character__visual--img sprite sprite-about_character_img03" />
							</div>
							<div class="about-character__intro">
								<h3 class="about-character__intro--heading">てるてるネコ</h3>
								<p class="about-character__intro--text">謎のてるてる坊主。何故かいつもマロを見守っている。</p>
							</div>
						</li>
					</ul>
				</div>
				<div class="about-rightnow__login">
					<p class="about-rightnow__login--text">SNSで話題！キャラクターたちの重すぎる<br>過去とは…？メニュー画面をチェック！</p>
					<a v-if="isAndroid" href="dcmdam://launch?url=http%3A%2F%2Fapp-manager.docomo.ne.jp%2FSpApps%2FdetailApp%3Fdcmstore_view%3Dnone%26cId%3D10000023012" class="about-rightnow__login--button" id="trackingWallpaperAppDownload02_android">便利なアプリをダウンロード<span>スゴ得ユーザーは使い放題！</span></a>
					<a v-else @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=appDownload`, _backPage: 'top' } })" id="trackingWallpaperAppDownload02_ios" class="about-rightnow__login--button">便利なアプリをダウンロード<span>スゴ得ユーザーは使い放題！</span></a>
				</div>
			</section>
		</div>
		<div class="wallpaper__back" @click="$router.push({ name: 'Cpsite', query: { id: sugotokuContentsId } })">TOPにもどる</div>
		<!-- /アウトロ -->
	</article>
</template>

<script>
// Vuex
import { mapActions, mapGetters } from 'vuex'
import { SET_IS_MY_PAGE } from '@/store/modules/common/mutation-types'

// ライブラリ
import cmnConst from '@/assets/js/constant.js'

export default {
	name: 'Wallpaper',
	components: {
	},
	data () {
		return {
			backendUrl: cmnConst.BACKEND_URL,
			sugotokuContentsId: cmnConst.SUGOTOKU_CONTENTS_ID,
			isLocalClass: ''
		}
	},
	computed: {
		// map Vuex getters
		...mapGetters('common', ['isMyPage', 'isAndroid'])
	},
	mounted () {
		this.isLocalClass = process.env.VUE_APP_PLATFORM === 'dev_sugotoku' ? 'is-local' : ''
		// CHANGED: コンテンツの高さを確保する処理の見直しのためコメントアウト
		// this.contentHeight()
	},
	beforeDestroy () {
	},
	methods: {
		// map Vuex actions
		...mapActions('common', [SET_IS_MY_PAGE]),

		// NOTE: 現在、未使用
		contentHeight () {
			// コンテンツの高さをemitする処理
			this.$nextTick(() => {
				setTimeout(() => {
					const domContainer = document.getElementById('js_wallpaper_container')
					if (domContainer) this.$emit('containerHeight', domContainer.clientHeight)
				}, 1000)
			})
		},

		clickCancelMypage (e) {
			e.preventDefault()
			this.SET_IS_MY_PAGE(true)
			// CHANGED: コンテンツの高さを確保する処理の見直しのためコメントアウト
			// this.contentHeight()
		},

		// NOTE: マイページ登録ボタンクリック処理
		clickMypageButton (e) {
			// NOTE: loadイベントでマイページ登録ボタンクリックのイベントリスナーを登録しているため、ページ読み込み時に「.dcm_favorite_separateButton a」がない場合はイベント発火しない。そのためフッターボタンのclickイベントを発火してマイページ登録する
			const footerMypageButton = document.getElementById('dcm_bookmarkArea')
			if (footerMypageButton) footerMypageButton.click()
			// マイページ登録イベントがある場合、2重処理になり動作がおかしくなるため、他のリスナー、デフォルトのイベントをキャンセルする
			e.stopImmediatePropagation()
			e.preventDefault()
			return false
		}
	}
}
</script>

<style lang="scss" scoped>
@import "~@/assets/sass/variable";

// NOTE: このページ限定の変数（デザインシステム外のため他での利用禁止）
$font-size-13: 0.812rem;
$font-size-15: 0.937rem;
$line-height-1_4: 1.4;
$spacing-22: 1.375rem;
$spacing-26: 1.625rem;
$spacing-30: 1.875rem;
$spacing-40: 2.5rem;
$wallpaper-primary: #1C2F6B;
$sugotoku-mypage-red: #d2193f;
$wallpaper-diagnose-blue: #0071DB;
$wallpaper-diagnose-red: #F95959;

.wallpaper {
	max-width: 375px;
	margin: 0 auto;
	font-family: $lp-family-primary;
	font-size: $font-size-15;

	&__header {
		margin: 0;
		line-height: $line-height-1;

		img {
			width: 100%;
			vertical-align: bottom;
		}
	}

	&__intro {
		margin: $spacing-20 auto 0;
		padding: 0 $spacing-10;
		line-height: $line-height-1_4;
	}

	&__contents {
		padding: 0 0 5.7143%;
		margin: $spacing-30 auto 0;
		background: url(~@/assets/img/moon-phase/bottom.png) no-repeat 0 100%, url(~@/assets/img/moon-phase/bg.png) repeat-y 0 0;
		background-size: 100% auto;
		box-sizing: border-box;

		&--title {
			margin: 0;
			line-height: $line-height-1;

			& > img {
				width: 100%;
				vertical-align: bottom;
			}
		}

		&--img {
			position: relative;
			width: 180px;
			height: 256px;
			margin: 0 auto;
			overflow: hidden;

			& > img {
				display: block;
				position: absolute;
				-webkit-touch-callout: default;
				// NOTE: 以下、カレンダー画像ごとにちょうど良い位置に調整する
				// bottom: -38px;
				// width: 430px;
				bottom: -13px;
				width: 380px;
				left: 50%;
				transform: translateX(-50%);

			}
		}

		&--text {
			width: 84.8%;
			margin: $spacing-20 auto 0;

			em {
				font-style: normal;
				font-weight: bold;
				color: $wallpaper-primary;
			}

			span { font-size: $font-size-13; }
		}
	}

	&__mypage {
		margin: $spacing-26 0 0 0;

		&--text {
			margin: 0;
			padding: 0 $spacing-10;
			font-size: $font-size-15;
			line-height: $line-height-1_4;
			text-align: center;

			& > em {
				font-style: normal;
				font-weight: bold;
				color: $sugotoku-mypage-red;
			}

			@media (max-width: (360px)) {
				font-size: $font-size-13;
			}
		}
	}

	&__appdownload {
		margin: $spacing-40 auto $spacing-30;

		.about-balloon {
			width: 345px;

			@media (max-width: (360px)) {
				width: 300px;
			}

			&__box {
				font-family: $lp-family-secondary;
				width: 256px;
				height: auto;
				margin: 0;
				background-color: $background-secondary;
				box-sizing: border-box;
				border-radius: 30px;
				line-height: $line-height-1_4;

				@media (max-width: (360px)) {
					width: 220px;
					font-size: $font-size-13;
				}
			}

			&.is-type01 {
				margin-top: $spacing-40;

				.about-balloon__box {
					padding: $spacing-10 $spacing-8 $spacing-8 $spacing-20;

					&::before {
						content: '';
						position: absolute;
						top: 50%;
						right: -28px;
						margin-top: -15px;
						border: 15px solid transparent;
						border-left: 30px solid $background-secondary;
						z-index: -1;
					}
				}
			}

			&.is-type02 .about-balloon__box {
				padding: $spacing-8 $spacing-10 $spacing-8 $spacing-10;

				&::before {
					content: '';
					position: absolute;
					top: 50%;
					left: -28px;
					margin-top: -15px;
					border: 15px solid transparent;
					border-right: 30px solid $background-secondary;
					z-index: -1;
				}
			}
		}

		.about-balloon__img {
			transform: scale(.54);
			transform-origin: 50% 50%;

			&.is-type01 {
				width: 69px;
				height: 61px;
			}

			&.is-type02 {
				width: 65px;
				height: 78px;
			}
		}

		.growthhack-f {
			&__title { margin: $spacing-6 auto 0;}
			&__allright { margin: $spacing-16 0 0 0; }
			&__caution { margin: $spacing-10 auto 0; }
		}

		.about-character {
			margin-top: $spacing-40;

			&__intro--text { font-size: $font-size-14; }

			.about-rightnow__login--text {
				margin: $spacing-30 0 0.375rem;
				font-size: $font-size-14;
			}
		}

		&--diagnose {
			margin: $spacing-20 auto 0;

			dt {
				text-align: center;
				font-weight: bold;
				font-size: $font-size-16;

				&.is-balance-check { color: $wallpaper-diagnose-blue; }
				&.is-meteoropathy-check { color: $wallpaper-diagnose-red; }

				@media (max-width: (360px)) {
					font-size: $font-size-14;
				}
			}

			dd {
				margin: 0;
				text-align: center;
				line-height: $line-height-1;

				img { width: 85.3334%; }
			}

			dd + dt { margin-top: $spacing-22; }
		}
	}

	&__back {
		display: block;
		appearance: none;
		color: $text-seconday;
		font-size: $font-size-24;
		background: $button-primary;
		border-radius: 27px;
		width: 74.6667%;
		margin: 0 auto;
		padding: $spacing-10 $spacing-10 $spacing-8;
		text-align: center;
		text-decoration: none;
	}
}
</style>
